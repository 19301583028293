{
    class aflVaultPaginationController {

    }

    angular.module('adminApp')
        .component('aflVaultPagination', {
            controller: aflVaultPaginationController,
            templateUrl: '/static/javascript/admin/afl-vault/afl-vault-pagination/afl-vault-pagination.html',
            bindings: {
                cursors: "=",
                currentPage: "=",
                lastPage: "=",
                onNextPage: "&",
                onPrevPage: "&",
                onFirstPage: "&",
                onLastPage: '&'
            }
        })
}
